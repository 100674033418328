var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    {
      class: { "is-highest": _vm.movie.highest, "is-lowest": _vm.movie.lowest }
    },
    [
      _c(
        "td",
        [
          _c(
            "router-link",
            {
              staticClass: "array-sorting__link",
              attrs: {
                tag: "a",
                to: {
                  name: "MovieDetailPage",
                  params: {
                    id: _vm.movie.id,
                    slug: _vm.$options.filters.slugify(_vm.movie.title)
                  }
                },
                "active-class": "is-active",
                "exact-active-class": "is-exact"
              }
            },
            [
              _c("img", {
                staticClass: "array-sorting__poster",
                attrs: {
                  src: "/img/movies/" + _vm.movie.poster,
                  alt: _vm.movie.title
                }
              })
            ]
          )
        ],
        1
      ),
      _c(
        "td",
        [
          _c(
            "router-link",
            {
              staticClass: "array-sorting__link",
              attrs: {
                tag: "a",
                to: {
                  name: "MovieDetailPage",
                  params: {
                    id: _vm.movie.id,
                    slug: _vm.$options.filters.slugify(_vm.movie.title)
                  }
                },
                "active-class": "is-active",
                "exact-active-class": "is-exact"
              }
            },
            [_vm._v(" " + _vm._s(_vm.movie.title) + " ")]
          )
        ],
        1
      ),
      _c("td", [_vm._v(_vm._s(_vm.movie.rating))]),
      _c("td", [_vm._v(_vm._s(_vm.movie.year))]),
      _c("td", [
        _vm._v(_vm._s(_vm.$options.filters.inlineList(_vm.movie.genre)))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }