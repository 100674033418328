var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "array-sorting" }, [
    _c("h1", { staticClass: "heading" }, [
      _vm._v("Sorting an array with Lodash")
    ]),
    _vm._m(0),
    _c("h2", { staticClass: "sub-heading" }, [_vm._v("Movie sorting")]),
    _c(
      "table",
      { staticClass: "array-sorting__movies", attrs: { cellspacing: "0" } },
      [
        _c("thead", [
          _c("tr", [
            _vm._m(1),
            _c("th", [
              _c(
                "a",
                {
                  class: [
                    _vm.orderByDesc ? "sorted-desc" : "sorted-asc",
                    { "is-active": _vm.order == "title" }
                  ],
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function($event) {
                      return _vm.setOrderBy("title")
                    }
                  }
                },
                [_c("span", [_vm._v("Title")])]
              )
            ]),
            _c("th", [
              _c(
                "a",
                {
                  class: [
                    _vm.orderByDesc ? "sorted-desc" : "sorted-asc",
                    { "is-active": _vm.order == "rating" }
                  ],
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function($event) {
                      return _vm.setOrderBy("rating")
                    }
                  }
                },
                [_c("span", [_vm._v("Rating")])]
              )
            ]),
            _c("th", [
              _c(
                "a",
                {
                  class: [
                    _vm.orderByDesc ? "sorted-desc" : "sorted-asc",
                    { "is-active": _vm.order == "year" }
                  ],
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function($event) {
                      return _vm.setOrderBy("year")
                    }
                  }
                },
                [_c("span", [_vm._v("Year")])]
              )
            ]),
            _vm._m(2)
          ])
        ]),
        _c(
          "tbody",
          _vm._l(_vm.filteredMovies, function(movie) {
            return _c("ArraySortingRow", {
              key: movie.id,
              attrs: { movie: movie }
            })
          }),
          1
        )
      ]
    ),
    _c("h2", { staticClass: "sub-heading" }, [_vm._v("Highest rated movie")]),
    _c("p", { staticClass: "paragraph" }, [
      _vm._v(
        _vm._s(_vm.highestRatedMovie.title) +
          ", rated a " +
          _vm._s(_vm.highestRatedMovie.rating) +
          " from the year " +
          _vm._s(_vm.highestRatedMovie.year) +
          "."
      )
    ]),
    _c("h2", { staticClass: "sub-heading" }, [_vm._v("Lowest rated movie")]),
    _c("p", { staticClass: "paragraph" }, [
      _vm._v(
        _vm._s(_vm.lowestRatedMovie.title) +
          ", rated a " +
          _vm._s(_vm.lowestRatedMovie.rating) +
          " from the year " +
          _vm._s(_vm.lowestRatedMovie.year) +
          "."
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "paragraph" }, [
      _vm._v("State of the sorting is stored in "),
      _c("strong", [_vm._v("Vuex")]),
      _vm._v(".")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [_c("span", [_vm._v("Poster")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [_c("span", [_vm._v("Genre")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }